import React from 'react'
import Layout from '../../components/layout';

import { longforms } from "../../data/longforms";
import Template from "./../../templates/longform";

const TurkboxPage = () => {
  return(
  <Layout>
      <Template
        title={longforms[0].post.title}
        coverUrl={longforms[0].post.coverUrl}
        type={longforms[0].post.type}
        timeline={longforms[0].post.timeline}
        challenge={longforms[0].post.challenge}
        solution={longforms[0].post.solution}
        awards={longforms[0].post.awards}
        links={longforms[0].post.links}
        body={longforms[0].post.body}
      />
  </Layout>
  )
}

export default TurkboxPage
